import { graphql, useStaticQuery } from 'gatsby'

export const useAboutUsSmallImage = () => {
	const {
		allStrapiAboutUs: { nodes },
	} = useStaticQuery(graphql`
		query allStrapiAboutUsSmallImage {
			allStrapiAboutUs(limit: 10) {
				nodes {
					smallImage {
						childImageSharp {
							gatsbyImageData(
								formats: WEBP
								placeholder: BLURRED
								layout: FULL_WIDTH
								transformOptions: { cropFocus: CENTER }
								width: 300
								height: 450
							)
						}
					}
				}
			}
		}
	`)
	return nodes[0]
}
