export const surgeryHours = [
  {
    day: 'Pondelok',
    time: '07:00-15:30',
    doctor: 'MUDr. Kňazíková',
  },
  {
    day: 'Utorok',
    time: '07:00-15:30',
    doctor: 'MUDr. Červeňová',
  },
  {
    day: 'Streda',
    time: '07:00-15:30',
    doctor: 'MUDr. Červeňová',
  },
  {
    day: 'Štvrtok',
    time: '07:00-15:30',
    doctor: 'MUDr. Kňazíková',
  },
  {
    day: 'Piatok',
    time: '07:00-15:30',
    doctor: 'MUDr. Kňazíková',
  },
  {
    day: 'Sobota',
    time: 'Neordinujeme',
    doctor: '',
  },
  {
    day: 'Nedeľa',
    time: 'Neordinujeme',
    doctor: '',
  },
]