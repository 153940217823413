import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'

export const useAboutUsImageText = () => {
	const {
		allStrapiAboutUs: { nodes },
	} = useStaticQuery(graphql`
		query allStrapiAboutUs {
			allStrapiAboutUs(limit: 10) {
				nodes {
					ImageText {
						description
						title
						image {
							childImageSharp {
								gatsbyImageData(
									formats: WEBP
									placeholder: BLURRED
									layout: CONSTRAINED
									transformOptions: { cropFocus: CENTER }
									quality: 90
									height: 700
								)
							}
						}
					}
				}
			}
		}
	`)
	return nodes[0].ImageText
}
