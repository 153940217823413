import React from 'react'
import { Container } from '../Shared/Container/Container'
import { Representation } from '../Shared/Representation/Representation'
import { AboutUsFactsSection } from './components/AboutUsFacts/AboutUsFactsSection'
import { AboutUsGallery } from './components/AboutUsGallery/AboutUsGallery'
import { AboutUsLeftContent } from './components/AboutUsImageText/AboutUsLeftContent'
import { AboutUsRightContent } from './components/AboutUsImageText/AboutUsRightContent'

export interface AboutUsProps {
	className?: string
}

export const AboutUs = (props: AboutUsProps) => {
	return (
		<div className="md:pb-48 pb-24">
			<Container className="">
				<section id="section_intro" className="flex justify-center md:space-x-8 flex-col md:flex-row">
					<div className=" md:w-1/3 w-full">
						<AboutUsLeftContent />
					</div>
					<div className="md:w-2/3 w-full">
						<AboutUsRightContent />
					</div>
				</section>
			</Container>

			<div id="section_facts">
				<AboutUsFactsSection />
			</div>

			<Container>
				<section id="section_gallery">
					<AboutUsGallery />
				</section>
			</Container>
		</div>
	)
}
