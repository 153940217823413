import { getImage, GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import Masonry from 'react-masonry-css'
import { useAboutUsGallery } from './hooks/useAboutUsGallery'

export interface AboutUsGalleryProps {
	className?: string
}

export const AboutUsGallery = (props: AboutUsGalleryProps) => {
	const { className = '' } = props
	const { titleClinic, OurClinicGallery } = useAboutUsGallery()
	const clinicGallery = OurClinicGallery.galleryImage

	const breakpointColumnsObj = {
		default: 2,
		500: 1,
	}

	return (
		<div className={`${className} md:mt-56 mt-32 pb-14`}>
			<div className="flex justify-start mb-4">
				<h2>{titleClinic.title}</h2>
				{/* <p className="mt-5 text-terciary"> {titleClinic.subtitle}</p> */}
			</div>
			<div className="md:w-10/12 mx-auto">
				<Masonry
					breakpointCols={breakpointColumnsObj}
					className="my-masonry-grid"
					columnClassName="my-masonry-grid_column "
				>
					{/* array of JSX items */}
					{clinicGallery.map((image: IGatsbyImageData, index: number) => (
						<GatsbyImage
							image={getImage(image.photo.childImageSharp)}
							alt={`Banská Bystrica očná klinika - fotka${index}`}
							placeholder="blurred"
							imgClassName=""
							className={` object-contain w-full gatsby-image-custom-wrapper`}
						/>
					))}
				</Masonry>
			</div>
		</div>
	)
}
