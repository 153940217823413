import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { useAboutUsSmallImage } from '../hooks/useAboutUsSmallImage'
import { surgeryHours } from './SurgeryHours'

export interface AboutUsTimetableProps {
	className?: string
}

export const AboutUsTimetable = (props: AboutUsTimetableProps) => {
	const { className = '' } = props
	const { smallImage } = useAboutUsSmallImage()
	console.log(smallImage)
	return (
		<div className="flex justify-center items-end relative md:top-32 top-12">
			<div className="w-96 md:block hidden">
				<GatsbyImage
					image={getImage(smallImage)}
					alt="Očná ambulancia Banská Bystrica"
					placeholder="blurred"
					imgClassName="object-center"
					className="object-center py-36"
				/>
			</div>
			<div className="bg-primary max-w-2xl md:py-10 py-8 md:px-12 px-4" id="ordinacne-hodiny">
				<h3 className="text-white text-center">Ordinačné hodiny</h3>
				<p className="text-blue-200 text-center mt-2 mb-8"></p>

				<div className="">
					{surgeryHours.map((item, index) => (
						<div key={index} className=" grid grid-cols-3 text-white mb-1 gap-2 ">
							<p className="md:text-base text-sm">{item.day}</p>
							<p className="font-bold md:text-base text-xs">{item.time}</p>
							<p className=" md:text-base text-sm">{item.doctor}</p>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}
