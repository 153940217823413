import React from 'react'
import { useAboutUsFacts } from '../hooks/useAboutUsFacts'
import { IGatsbyImageData, GatsbyImage, getImage } from 'gatsby-plugin-image'

export interface AboutUsFactsProps {
	className?: string
}

export const AboutUsFacts = (props: AboutUsFactsProps) => {
	const { className = '' } = props
	const facts = useAboutUsFacts()

	return (
		<>
			<h2 className="text-white  md:text-left text-center mb-8">Fakty o očnej ambulancii </h2>
			<div className="flex md:space-y-0 justify-between space-x-4 md:flex-row flex-col space-y-10 ">
				{facts.map((fact: Fact, index: number) => (
					<div className="flex flex-col md:items-start items-center">
						<div className="icon bg-primary rounded-full p-5 block mx-auto">
							<GatsbyImage
								image={getImage(fact.icon)}
								alt={'name'}
								placeholder="blurred"
								imgClassName="object-center"
								className="object-center mx-auto"
							/>
						</div>
						<p className="font-bold mt-4 mb-2 text-white md:text-left text-center">{fact.title}</p>
					</div>
				))}
			</div>
		</>
	)
}
