import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'

export interface Gallery {
	title: string
	description: string
	image: GatsbyImage
	OurClinicGallery: any
	titleClinic: any
}

export const useAboutUsFacts = () => {
	const {
		allStrapiFacts: { nodes },
	} = useStaticQuery(graphql`
		query allStrapiFacts {
			allStrapiFacts(limit: 10) {
				nodes {
					title
					icon {
						childImageSharp {
							gatsbyImageData(
								formats: WEBP
								placeholder: BLURRED
								layout: CONSTRAINED
								transformOptions: { cropFocus: CENTER }
								width: 50
							)
						}
					}
				}
			}
		}
	`)
	return nodes
}
