import React from 'react'
import { AboutUs } from '../components/AboutUs/AboutUs'
import { Container } from '../components/Shared/Container/Container'
import Layout from '../components/Shared/Layout/Layout'
import { PageHeader } from '../components/Shared/PageHeader/PageHeader'
import { SEO } from '../components/Shared/SEO/SEO'

const ServicesPage = () => (
	<Layout>
		<SEO
			description="Očná ambulancia v centre Banskej Bystrice ponúka komplexnú zdravotnú starostlivosť, ľudský prístup, príjemné prostredie a minimálny čas strávený čakaním na vyšetrenie vďaka bezplatnému objednávaniu na čas."
			title="O nás"
		/>
		<PageHeader
			pageTitle="O nás"
			pageIntroText="Očná ambulancia v centre Banskej Bystrice ponúka komplexnú zdravotnú starostlivosť, ľudský prístup, príjemné prostredie a minimálny čas strávený čakaním na vyšetrenie vďaka bezplatnému objednávaniu na čas."
			buttonText="Dohodnúť si termín"
			buttonUrl="/kontakt"
		/>
		<AboutUs />
	</Layout>
)

export default ServicesPage
