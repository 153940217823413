import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'

export interface Gallery {
	title: string
	description: string
	image: GatsbyImage
	OurClinicGallery: any
	titleClinic: any
}

export const useAboutUsGallery = (): Gallery => {
	const {
		allStrapiAboutUs: { nodes },
	} = useStaticQuery(graphql`
		query allStrapiAboutUsGallery {
			allStrapiAboutUs(limit: 10) {
				nodes {
					titleClinic {
						subtitle
						title
					}
					OurClinicGallery {
						galleryImage {
							photo {
								childImageSharp {
									gatsbyImageData(formats: WEBP, layout: FULL_WIDTH, height: 450)
								}
							}
						}
					}
				}
			}
		}
	`)
	return nodes[0]
}
