import React from 'react'
import { CONTACT } from '../../../../lib/consts'
import { FiPhoneCall } from 'react-icons/fi'
import { useAboutUsImageText } from './hooks/useAboutUsImageText'
import ReactMarkdown from 'react-markdown'
import { TelNumber } from '../../../Shared/TelNumber/TelNumber'

export interface AboutUsRightContentProps {
	className?: string
}

export const AboutUsRightContent = (props: AboutUsRightContentProps) => {
	const { className = '' } = props
	const { title, description } = useAboutUsImageText()

	return (
		<div className={`${className} relative   pt-7 flex flex-col justify-between h-full`}>
			<div>
				<h2>{title}</h2>
				<div className="mt-4">
					{<ReactMarkdown children={description} className="font-primary text-terciary" />}
				</div>
			</div>

			<div className="max-w-xs relative md:-left-36 mb-20 bg-white shadow-md rounded-tr-3xl rounded-bl-3xl py-5 md:px-14 px-4">
				<span className="absolute top-8 left-4 z-0">
					<FiPhoneCall className="text-gray-100 text-5xl" />
				</span>
				<div className="z-10 relative">
					<p className="text-2xl">Zavolajte nám</p>

					<a href={`tel:${CONTACT.phone}`} className="font-bold text-2xl hover:opacity-70">
						{CONTACT.phone}
					</a>
				</div>
			</div>
		</div>
	)
}
