import { GatsbyImage, getImage, IGatsbyImageData, IImage } from 'gatsby-plugin-image'
import React from 'react'
import { useAboutUsImageText } from './hooks/useAboutUsImageText'

export interface AboutUsLeftContentProps {
	className?: string
}

export const AboutUsLeftContent = (props: AboutUsLeftContentProps) => {
	const { className } = props
	const { image } = useAboutUsImageText()
	const introImage = getImage(image)

	return (
		<div className={`${className}`}>
			<GatsbyImage
				image={introImage}
				alt={'name'}
				placeholder="blurred"
				imgClassName="object-center"
				className="object-center"
			/>
		</div>
	)
}
