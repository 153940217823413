import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { IGatsbyImageData, GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { Container } from '../../../Shared/Container/Container'
import { AboutUsFacts } from './components/AboutUsFacts'
import { AboutUsTimetable } from './components/AboutUsTimetable'

export interface AboutUsFactsSectionProps {
	className?: string
}

export interface Fact {
	title: string
	description: string
	icon: IGatsbyImageData
}

export const AboutUsFactsSection = ({}) => (
	<StaticQuery
		query={graphql`
			query {
				desktop: file(relativePath: { eq: "images/factsbg.jpg" }) {
					childImageSharp {
						fluid(quality: 90, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`}
		render={(data: any) => {
			const imageData = data.desktop.childImageSharp.fluid

			return (
				<section className="pt-20">
					<BackgroundImage Tag="section" className="" fluid={imageData} background-size="cover">
						<Container className={`py-20`}>
							<AboutUsFacts />
							<div className="flex justify-center">
								<AboutUsTimetable />
							</div>
						</Container>
					</BackgroundImage>
				</section>
			)
		}}
	/>
)
